<style>
.center {
  width: 100%;
  height: 1080px;
  background-image: url('https://ideasranch.top/web_resources/pexels-introspectivedsgn-7809123.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* 当屏幕宽度小于等于768px时，应用以下样式 */
@media screen and (max-width: 768px) {
  .center {
    margin-top: -15vh;
    width: 100%;
    height: 1080px;
    background-image: url('https://ideasranch.top/web_resources/back.jpg');
    background-size: 250% 110%;
    background-repeat: no-repeat;
    background-position: 50% center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .overlaytitleH2 {
      font-size: 22px;
      color: #fff;
    }

    .buttongohs:hover {
      background-color: rgba(31, 13, 3, 0.55);
      box-shadow: 0 14px 28px rgba(175, 50, 50, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      border: 0;
      transition: 0.4s;
    }

    .h1-clazz {
      font-size: 24px;
    }

    .logon {
      z-index: 1;
      width: 768px;
      max-width: 100%;
      min-height: 480px;
      margin-top: 20px;
      border-bottom: none;
      display: flex;
      background: transparent;
    }
  }


  .overlaylong-Signin > input, .overlaylong-Signup > input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 10px 0;
    width: 120px;
  }
}

.h1-clazz {
  font-size: 30px;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  text-shadow: 2px 2px 4px #000000;
  transform: rotate(-3.5grad);
  animation: rotate3d 8s infinite;
}


@keyframes rotate3d {
  0% {
    color: white;
    font-size: 20px;
    transform: rotate3d(1, 1, 0.1, 0deg);
  }
  50% {
    color: red;
    font-size: 20px;
    transform: rotate3d(1, 1, 0.5, 180deg);
  }
  100% {
    color: orange;
    font-size: 20px;
    transform: rotate3d(1, 1, 1, 360deg);
  }
}

.buttongohs:hover {
  background-color: rgba(98, 96, 95, 0.55);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border: 0;
  transition: 0.4s;
}

.logon {
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
//box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); /* position: relative; overflow: hidden; */ width: 768px; max-width: 100%; min-height: 480px; margin-top: 20px; display: flex; background: -webkit-linear-gradient(right, #e30d0d, #e89d54);
}

.overlaylong {
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaylongleft {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaylongright {
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaytitle {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}


.overlaytitleH2 {
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
}

.overlaytitleP {
  font-size: 15px;
  color: #fff;
  margin-top: 20px;
}

.overlaytitleleft {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
}

.overlaytitleright {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-100%);
  transition: transform 0.6s ease-in-out;
}

.overlaytitle-Signin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlaytitle-Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttongohs {
  width: 180px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 40px;
  margin-top: 40px;
}

.overlaylongH2 {
  font-size: 25px;
  color: black;
  /* width: 250px; */
}

.overlaylong-Signin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlaylong-Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 10px 0;
  width: 240px;
}

h3 {
  font-size: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.inupbutton {
  background-color: #29eac4;
  border: none;
  width: 180px;
  height: 40px;
  border-radius: 50px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-top: 30px;
}
</style>

<template>
  <div class="center">
    <h1 class="h1-clazz" style="color: white">Tell right from wrong</h1>
    <!--    <hr/>-->
    <!--    <a href="#">IdeasRanch</a>-->
    <div class="logon">
      <div :class="overlaylong">
        <div class="overlaylong-Signin" v-if="disfiex == 0">
          <h2 class="overlaylongH2">登录</h2>
          <input type="text" v-model="formdata.userName">
          <input type="text" v-model="formdata.userPasswdInput" placeholder="password">
          <h3>Forgot your password?</h3>
          <button class="inupbutton" @click="handleLogin">Sign in</button>

        </div>
        <div class="overlaylong-Signup" v-if="disfiex == 1">
          <h2 class="overlaylongH2">注册</h2>
          <input type="text" v-model="formdata.userName">
          <input type="text" v-model="formdata.userPasswdInput" placeholder="password">
          <button class="inupbutton" @click="handleRegister">Sign up</button>
        </div>

      </div>
      <div :class="overlaytitle">
        <div class="overlaytitle-Signin" v-if="disfiex == 0">
          <h2 class="overlaytitleH2">你好，我的朋友!</h2>
          <p class="overlaytitleP">
            Greetings, my dear friend!
          </p>
          <div class="buttongohs" @click="Signin">暂无账号，点击注册</div>
        </div>
        <div class="overlaytitle-Signup" v-if="disfiex == 1">
          <h2 class="overlaytitleH2">欢迎回来!</h2>
          <p class="overlaytitleP">享受这场奇妙的冒险</p>
          <div class="buttongohs" @click="Signup">已有帐号，点击登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {login, register} from "@/api";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      overlaylong: 'overlaylong',
      overlaytitle: 'overlaytitle',
      disfiex: 0,
      formdata: {
        userName: "root",
        userPasswdInput: "123"
      }
    }
  },
  created() {
    this.checkIfLogin()
  },
  methods: {
    checkIfLogin() {
      // 获取登录
      const userToken = Cookies.get('userToken')
      if (userToken) {
        this.$message.success('您已登录，即将跳转')
        // 最多延迟 关闭 loading
        setTimeout(() => {
          this.$router.push('/')
        }, 800)
      }
    },
    handleRegister() {
      register(this.formdata).then(res => {
        if (res.code === 200) {
          this.$message.success("Good news ,register success~~")
          Cookies.set('userToken', res.data.userName, {expires: 1}); // 设置一个1天后过期的Cookie
          Cookies.set('nickName', res.data.nickName, {expires: 1}); // 设置一个1天后过期的Cookie
          this.setUserInfo(this.formdata)
          setTimeout(() => {
            this.$router.push('/'); // 假设你的登录页面路由为'/login'
          }, 1000); // 延迟2000毫秒（即2秒）
        }
      })
    },
    handleLogin() {
      login(this.formdata).then(res => {
        if (res.code === 200) {
          this.$message.success("Good news ,login success~~")
          Cookies.set('userToken', res.data.userName, {expires: 1}); // 设置一个1天后过期的Cookie
          Cookies.set('nickName', res.data.nickName, {expires: 1}); // 设置一个1天后过期的Cookie
          setTimeout(() => {
            location.reload()
          }, 1500); // 延迟2000毫秒（即2秒）
        }
      })
    },
    setUserInfo(userInfo) {
      // 设置用户信息
      this.$store.dispatch('setUserInfo', userInfo);
    },
    Signin() {
      this.overlaylong = "overlaylongleft"
      this.overlaytitle = "overlaytitleright"
      setTimeout(() => {
        this.disfiex = 1
      }, 200)
    },
    Signup() {
      this.overlaylong = "overlaylongright"
      this.overlaytitle = "overlaytitleleft"

      setTimeout(() => {
        this.disfiex = 0
      }, 200)

    }
  }
}
</script>